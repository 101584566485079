$ = jQuery;

var $log = function(msg) {
        if (App.debug) {
            if (arguments.length > 1) {
                var to_console = [];
                for (i = 1; i < arguments.length; i++) {
                    to_console[i - 1] = arguments[i][0];
                }
                console.log(msg, to_console);
            } else {
                console.log(msg);
            }
        }
    },
    App = {
        debug: true,
        init: function() {

            this.scripts();

        },
        loaded: function() {},
        scripts: function() {

            if (App.is_touch_device()) {
                $("body").addClass("touch");
            } else {
                $("body").addClass("no-touch");
            }

            var $win = $(window),
                $body = $("body"),
                scrollPos = $win.scrollTop();

            $win.on('scroll', function() {

                if ($(this).scrollTop() >= 50) {
                    $('body').addClass('page-scrolled');
                } else {
                    $('body').removeClass('page-scrolled');
                }

                scrollPos = $win.scrollTop();

            });

            // To top
            $(".back-top").on("click", function() {
                $("html,body").animate({
                    scrollTop: 0
                }, 550);
            });

            // Content link
            $(".content-link").click(function(e) {
                if ($(this).attr('data-slug').length && (typeof $(this).data('slug') !== 'undefined')) {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $('html,body').animate({
                        scrollTop: $('#' + id).offset().top - 50
                    }, 550);
                }
            });
            
            /*
            $('ul.gallery').find('a.fancyThumb').fancybox({
                'transitionIn': 'elastic',
                'transitionOut': 'elastic',
                'speedIn': 600,
                'speedOut': 200,
                'overlayShow': false
            });

            $(".youtube").fancybox({
                'transitionIn': 'none',
                'transitionOut': 'none'
            });
            */
            
            var video_block = $('.video-block p');
            
            if(video_block && video_block.length){
                video_block.lightGallery({
                    thumbnail: false,
                    loadYoutubeThumbnail: false,
                    //selector: '[data-rel="lightbox"]',
                    youtubePlayerParams: {
                        modestbranding: 1,
                        showinfo: 0,
                        rel: 0,
                        controls: 0
                    },
                    vimeoPlayerParams: {
                        byline : 0,
                        portrait : 0,
                        color : 'A90707'     
                    },
                });
            }
            
            $('.gallery, #plotsMap').lightGallery({
                thumbnail: true,
                selector: 'a'
            });

            /* prepend menu icon */

            /* toggle main menu nav */
            $(".menu-icon").on("click", function() {
                $(".menu-wrap .menu").slideToggle();
                $(this).toggleClass("active");
            });
            /* toggle inv obj menu nav */
            $(".inv-menu-icon").on("click", function() {
                $(".invest-menu-wrap .menu").slideToggle();
                $(this).toggleClass("active");
            });

            /* toggle sidebar accordion */
            $(".sidebar-box .trigger").on("click", function() {
                $(".sidebar-content, .side-shadow").slideToggle();
                $(this).toggleClass("opened");
            });

            /* toggle footer columns for mobile */
            $(".footer-column h3").on("click", function() {
                $(this).next('ul,p').slideToggle();
                $(this).parent().toggleClass("opened");
            });

            /* Partners touch slider
            $("#partners-slider").touchCarousel({
                pagingNav:false,snapToItems:false,itemsPerMove:1,scrollToLast:false,loopItems:false,scrollbar:true
            }); */

            /*
            $('.trigger').click(function(e){
                e.preventDefault();
                //$(this).addClass('opened');
                $(this).addClass('opened');
                if($('.trigger').hasClass('opened') && $('.collapse').hasClass('in')){
                    $('.trigger').removeClass('opened');
                }
            });
            */

            setInterval(function() {
                updateClock();
            }, 1000);

            var updateClock = function() {
                var currentTime = new Date();

                var day = currentTime.getDate();
                var month = currentTime.getMonth() + 1;
                var year = currentTime.getFullYear();
                var hours = currentTime.getHours();
                var minutes = currentTime.getMinutes();
                var seconds = currentTime.getSeconds();

                day = (day < 10 ? '0' : '') + day;
                month = (month < 10 ? '0' : '') + month;
                hours = (hours < 10 ? '0' : '') + hours;
                minutes = (minutes < 10 ? '0' : '') + minutes;
                seconds = (seconds < 10 ? '0' : '') + seconds;

                // Compose the string for display
                var currentTimeString = day + '.' + month + '.' + year + ' | ' + hours + ':' + minutes + ':' + seconds;

                $('#datetimer').text(currentTimeString);
            };

        },

        mobMenu: function(menuWrapper) {

            $this = menuWrapper;

            /* toggle main menu nav */
            $(".menu-btn", $this).on("click", function() {

                menuWrapper.toggleClass("opened");
                $("body").toggleClass("opened");

            });

            $(window).on("keyup", function(event) {

                if ($("body").hasClass("opened")) {
                    switch (event.keyCode) {
                        case 27: //esc
                            menuWrapper.removeClass("opened");
                            $("body").removeClass("opened");
                            break;
                    }
                }

            });

        },
        is_touch_device: function() {
            return (("ontouchstart" in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        }

    };

/*$(document).on("ready", function() {

    App.init();

});*/


$(window).on("load", function() {

    App.init();
    App.mobMenu($(".page-header"));

});